import React, {useState} from "react";
import {
  Box,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import {observer} from "mobx-react-lite";
import {InfiniteScroll} from "ui-components/scrollingTable";
import {parseUnixTime} from "lib/features";
import {ORDER_STATUS_DELETED, ORDER_STATUS_ISSUED, orderStatuses} from "lib/orderStatuses";
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import {LoadingButton, Pagination} from "@mui/lab";
import {$transactions} from "store/transactions/transactions";
import {DELIVERY_PRODUCT_ID} from "../chat/customerInfo/order/returnProductButton";
import {$user} from "../../store/user";

const columns = [
  {id: "payment_id", label: "ID платежа"},
  {id: "transaction_type", label: "Тип"},
  {id: "object", label: "Объект"},
  {id: "store", label: "ПВЗ"},
  {id: "created_at", label: "Дата создания платежа"},
  {id: "has_paid", label: "Оплачен"},
  {id: "receipt_id", label: "Чек отправлен"},
  {id: "has_orf_receipt", label: "Чек сел в ОФД"},
  {id: "status", label: "Статус заказа"},
  {id: "actions", label: "Действия", minWidth: "112px", align: "right"},
];

const ReceiptRow = ({transaction}) => {
  const [loading, setLoading] = useState(false);

  const checkOfdReceipt = (receiptId) => {
    setLoading(true);
    $transactions.checkOfdReceipt(receiptId).finally(() => setLoading(false));
  };

  const createReceipt = (receiptId) => {
    setLoading(true);
    $transactions.sendOfdReceipt(receiptId).finally(() => setLoading(false));
  };

  const isSendReceiptDisabled = (transaction) => (
    (transaction.has_ofd_receipt && !transaction.has_ofd_receipt_error) ||
    transaction.payment?.status !== 2 ||
    !$user.isDeveloper
  );

  const renderChipColor = (status) => {
    if (status === ORDER_STATUS_ISSUED) {
      return "success"
    } else if (status === ORDER_STATUS_DELETED) {
      return "error"
    } else {
      return "primary"
    }
  }

  return (
    <TableRow hover tabIndex={-1}>
      <TableCell>{transaction.payment?.order_identity}</TableCell>
      <TableCell>
        <span style={{color: transaction.transaction_type === 'payment' ? 'green' : 'red'}}>
          {transaction.transaction_type === 'payment' ? 'приход' : 'возврат'}
        </span>
      </TableCell>
      <TableCell>
        {transaction.order_id && !transaction.product_id && `Заказ №${transaction.order_id}`}
        {transaction.product_id && `${transaction.product_id === DELIVERY_PRODUCT_ID ? 'Доставка' : `Товар #${transaction.product_id}`}`}
      </TableCell>
      <TableCell>{transaction.order?.store.name}</TableCell>
      <TableCell>{parseUnixTime(transaction.created_at)}</TableCell>
      <TableCell align="center">
        {transaction.order?.paid == 2 && <CheckIcon style={{color: 'green', fontSize: '30px'}}/>}
      </TableCell>
      <TableCell align="center">
        {transaction.receipt_id && <CheckIcon style={{color: 'green', fontSize: '30px'}}/>}
        {transaction.order?.status === ORDER_STATUS_ISSUED && !transaction.receipt_id && (
          <Tooltip title="Заказ уже выдан, а чек не был отправлен">
            <ErrorOutlineOutlinedIcon style={{color: 'red', fontSize: '30px'}}/>
          </Tooltip>
        )}
      </TableCell>
      <TableCell align="center">
        {!!transaction.has_ofd_receipt && <CheckIcon style={{color: 'green', fontSize: '30px'}}/>}
        {!!transaction.has_ofd_receipt_error && <ErrorOutlineOutlinedIcon style={{color: 'red', fontSize: '30px'}}/>}
      </TableCell>
      <TableCell>
        {transaction.order?.status.toString() && (
          <Chip
            label={orderStatuses[transaction.order?.status]}
            color={renderChipColor(transaction.order?.status)}
            size="small"
          />
        )}
      </TableCell>
      <TableCell align="right">
        <Tooltip title="">
          <LoadingButton
            loading={loading}
            disabled={isSendReceiptDisabled(transaction)}
            onClick={() => createReceipt(transaction.id)}
            sx={{mb: 1, minWidth: '120px'}} color="secondary" variant="contained" size="small">
            Отправить чек
          </LoadingButton>
        </Tooltip>
        <LoadingButton
          sx={{ml: 1, mb: 1, minWidth: '120px'}}
          loading={loading}
          onClick={() => checkOfdReceipt(transaction.receipt_id)}
          variant="contained"
          disabled={!transaction.receipt_id}
          size="small">
          Проверить чек
        </LoadingButton>
      </TableCell>
    </TableRow>
  );
};

const ReceiptsTable = observer(() => {
  const handleChangePaginate = (page) => $transactions.setPage(page);

  return (
    <InfiniteScroll>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column.id} align={column.align} style={{minWidth: column.minWidth}}>
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {$transactions.list.map((transaction, key) => (
            <ReceiptRow key={key} transaction={transaction}/>
          ))}
        </TableBody>
      </Table>
      <Box sx={{my: 4, display: 'flex', justifyContent: 'center'}}>
        <Pagination
          onChange={(e, page) => handleChangePaginate(page)}
          variant="outlined"
          page={$transactions.currentPage}
          count={Math.ceil($transactions.totalCount / $transactions.limit)}
          shape="rounded"
        />
      </Box>
      <Box sx={{mb: 2}}>записей: {$transactions.totalCount}</Box>
    </InfiniteScroll>
  );
});

export default ReceiptsTable;