import {observer} from "mobx-react-lite";
import React, {useEffect} from "react";
import {
  Box,
  Button,
  FormControl,
  Autocomplete,
  TextField, Tooltip,
} from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import EditIcon from "@mui/icons-material/Edit";
import Add from "@mui/icons-material/Add";
import {$products} from "store/badges/productsInBadge";
import {$modals} from "store/modals";
import {isMobile} from "react-device-detect";
import ProductsTable from "./productsTable";
import {
  NEW_BADGE_MODAL,
  EDIT_BADGE_MODAL,
  ADD_PRODUCT_TO_BADGE_MODAL, UPLOAD_TXT_TO_BADGE_MODAL,
} from "ui-components/modal/modals";
import {$sidebar} from "store/sidebar";
import {$badges} from "store/badges/badges";
import CircleIcon from '@mui/icons-material/Circle';

const Badges = observer(() => {
  useEffect(() => {
    $badges.fetchBadges("fetchAll");
    $sidebar.setPageTitle("Бейджи");
  }, []);

  const handleChange = (newValue) => {
    $products.setCurrentBadge(newValue);
    $products.fetchProductsInBadgeList($products.currentBadge);
  };
  const addItem = () => {
    $modals.show(ADD_PRODUCT_TO_BADGE_MODAL);
  };
  const uploadFile = () => {
    $modals.show(UPLOAD_TXT_TO_BADGE_MODAL, $products.currentBadge);
  };
  const downloadCsv = () => {
    $products.downloadBarcodesFile($products.currentBadge);
  };
  const ButtonWrapper = ({children}) => (
    <Box sx={isMobile ? {fontSize: 12, ml: 0, mb: 2} : {fontSize: 18, ml: 2}}>
      {children}
    </Box>
  );

  const createTag = () => {
    $modals.show(NEW_BADGE_MODAL);
  };

  const editTag = () => {
    $modals.show(EDIT_BADGE_MODAL, $products.currentBadge);
  };

  return (
    <>
      <Box
        sx={
          isMobile
            ? {}
            : {display: "flex", justifyContent: "space-between", mb: 2}
        }
      >
        <Box
          sx={isMobile ? {} : {display: "flex", justifyContent: "flex-start"}}
        >
          <FormControl
            fullWidth={isMobile}
            size="small"
            sx={{minWidth: 300, mb: isMobile ? 2 : 0}}
          >
            {$badges.list && (
              <Autocomplete
                key={$badges.list.length}
                size="small"
                clearOnBlur
                disableClearable
                getOptionLabel={(option) => `ID${option.id} ${option.title}`} // Возвращаем только строку
                onChange={(event, newValue) => {
                  handleChange(newValue);
                }}
                options={$badges.list}
                renderInput={(params) => (
                  <TextField {...params} label="Выберите бейдж" />
                )}
                renderOption={(props, option) => ( // Используем renderOption для отображения JSX
                  <li {...props}>
                    <Tooltip title="Активность">
                      <CircleIcon
                        style={{ color: option.is_active ? 'green' : 'red', fontSize: '10px', marginRight: '6px' }} />
                    </Tooltip>
                    <div style={{
                      background: option.bg_color,
                      // border: `1px solid ${option.text_color}`,
                      borderRadius: '5px',
                      padding: '2px 4px',
                      color: option.text_color ? option.text_color : '#000'
                    }}>
                      {option.title}
                    </div>
                  </li>
                )}
              />
            )}
          </FormControl>
          <div
            style={
              isMobile ? {} : {display: "flex", justifyContent: "flex-start"}
            }
          >
            <ButtonWrapper>
              <Button
                fullWidth={isMobile}
                variant="contained"
                startIcon={<Add/>}
                onClick={createTag}
              >
                Создать
              </Button>
            </ButtonWrapper>
            {$products.currentBadge.id && (
              <ButtonWrapper>
                <Button
                  fullWidth={isMobile}
                  variant="contained"
                  startIcon={<EditIcon/>}
                  onClick={editTag}
                >
                  Редактировать
                </Button>
              </ButtonWrapper>
            )}
          </div>
        </Box>
        {$products.currentBadge.id && (
          <div
            style={
              isMobile ? {} : {display: "flex", justifyContent: "flex-end"}
            }
          >
            <ButtonWrapper>
              <Button
                fullWidth={isMobile}
                variant="contained"
                startIcon={<Add/>}
                onClick={addItem}
              >
                Добавить товар
              </Button>
            </ButtonWrapper>
            <ButtonWrapper>
              <Button
                fullWidth={isMobile}
                variant="contained"
                startIcon={<AttachFileIcon/>}
                onClick={uploadFile}
              >
                Загрузить файл
              </Button>
            </ButtonWrapper>
            {/*{$products.productsInBadgeList.length > 0 && (*/}
            {/*  <ButtonWrapper>*/}
            {/*    <Button*/}
            {/*      fullWidth={isMobile}*/}
            {/*      variant="contained"*/}
            {/*      startIcon={<UploadFileIcon/>}*/}
            {/*      onClick={downloadCsv}*/}
            {/*    >*/}
            {/*      Выгрузить CSV*/}
            {/*    </Button>*/}
            {/*  </ButtonWrapper>*/}
            {/*)}*/}
          </div>
        )}
      </Box>
      {!isMobile && <ProductsTable list={$products.productsInBadgeList}/>}
    </>
  );
});

export default Badges;
