import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  IconButton,
} from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import React from "react";
import { observer } from "mobx-react-lite";
import { InfiniteScroll } from "ui-components/scrollingTable";
import { $modals } from "store/modals";
import { $products } from "store/tags/productsInTags";
import {DELETE_PRODUCT_FROM_BADGE_MODAL} from "ui-components/modal/modals";

const columns = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "barcode",
    label: "Штрих-код",
  },
  {
    id: "name",
    label: "Наименование",
  },
  {
    id: "actions",
    label: "Действия",
  },
];

const ProductsTable = observer(({ list }) => {
  const deleteProduct = (product) => {
    $modals.show(DELETE_PRODUCT_FROM_BADGE_MODAL, product);
  };

  return (
    <InfiniteScroll
      hasMore={$products.hasMore}
      onScroll={() => $products.paginate()}
    >
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {list.length > 0 &&
            list.map((listItem, key) => (
              <TableRow hover tabIndex={-1} key={key}>
                <TableCell>{listItem.product.id}</TableCell>
                <TableCell>{listItem.product.barcodes?.[0].barcode}</TableCell>
                <TableCell>{listItem.product.name}</TableCell>
                <TableCell>
                  <Tooltip title="Удалить">
                    <IconButton onClick={() => deleteProduct(listItem.product)}>
                      <DeleteOutlineOutlinedIcon color="error" />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </InfiniteScroll>
  );
});

export default ProductsTable;
