import React from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  MenuItem,
  TextField,
  InputAdornment,
  Grid
} from "@mui/material";
import {Modal} from "ui-components/modal";
import {$modals} from "store/modals";
import {observer} from "mobx-react-lite";
import {$badges, badgeTypes} from "store/badges/badges";
import {LoadingButton} from "@mui/lab";
import ColorizeIcon from '@mui/icons-material/Colorize';
import {ColorPicker} from "./colorPicker";
import {Controller, useForm} from "react-hook-form";
import FormatColorFillIcon from "@mui/icons-material/FormatColorFill";

const NewBadgeModal = observer(() => {
  const {
    control,
    handleSubmit,
    setValue,
    setError,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: '',
      text_color: '',
      bg_color: '',
      expired_at: '',
      url: '',
      type: 'info',
      is_active: true,
      is_visible: true,
    },
  });

  const [btnLoading, setBtnLoading] = React.useState(false);
  const [colorPicker, setColorPicker] = React.useState(null);

  const onSubmit = async (data) => {
    setBtnLoading(true);

    try {
      await $badges.create(data);
      await $badges.fetchBadges("fetchAll");
      $modals.close();
    } catch (err) {
      if (err.response?.data && err.response.status === 422) {
        const errorResponse = err.response.data.errors;
        for (const key in errorResponse) {
          setError(key, { type: 'manual', message: errorResponse[key][0] }); // Устанавливаем ошибки вручную
        }
      }
    } finally {
      setBtnLoading(false);
    }
  };

  const setColorClicked = (type) => {
    setColorPicker(type === colorPicker ? null : type);
  };

  const onChangeColor = (value) => {
    if (colorPicker === 'text') {
      setValue('text_color', value);
    } else {
      setValue('bg_color', value);
    }
  };

  return (
    <Modal title="Создать бейдж">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="title"
          control={control}
          rules={{ required: "Название обязательно" }}
          render={({ field }) => (
            <TextField
              {...field}
              required
              fullWidth
              sx={{ mb: 3 }}
              label="Название"
              variant="outlined"
              error={!!errors.title}
              helperText={errors.title?.message}
            />
          )}
        />

        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <Controller
              name="text_color"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment onClick={() => setColorClicked('text')} sx={{ cursor: 'pointer' }} position="end">
                        <ColorizeIcon sx={{ color: colorPicker === 'text' ? '#067ed5' : '#101010' }} />
                      </InputAdornment>
                    ),
                  }}
                  required
                  fullWidth
                  sx={{ mb: 3 }}
                  label="Цвет (пример #FFFFFF)"
                  variant="outlined"
                  error={!!errors.text_color}
                  helperText={errors.text_color?.message}
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Controller
              name="bg_color"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment onClick={() => setColorClicked('bg')} sx={{ cursor: 'pointer' }} position="end">
                        <FormatColorFillIcon sx={{ color: colorPicker === 'bg' ? '#067ed5' : '#101010' }} />
                      </InputAdornment>
                    ),
                  }}
                  required
                  fullWidth
                  sx={{ mb: 3 }}
                  label="Фон (пример #000000)"
                  variant="outlined"
                  error={!!errors.bg_color}
                  helperText={errors.bg_color?.message}
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>
        </Grid>

        {colorPicker && (
          <ColorPicker
            title={watch('title')}
            type={colorPicker}
            textColor={watch('text_color')}
            bgColor={watch('bg_color')}
            setColor={onChangeColor}
          />
        )}

        <Controller
          name="expired_at"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              sx={{ mb: 3 }}
              label="Дата окончания (не обязательное поле)"
              variant="outlined"
              type="date"
              error={!!errors.expired_at}
              helperText={errors.expired_at?.message}
              InputLabelProps={{ shrink: true }} // Убедитесь, что метка не перекрывает поле
            />
          )}
        />
        <Controller
          name="url"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              sx={{ mb: 3 }}
              label="Ссылка на правила"
              variant="outlined"
              error={!!errors.url}
              helperText={errors.url?.message}
            />
          )}
        />
        <Controller
          name="type"
          control={control}
          render={({ field }) => (
            <TextField
              sx={{ mb: 3 }}
              {...field}
              required
              select
              fullWidth
              label="Тип"
              variant="outlined"
            >
              {badgeTypes.map((el) => (
                <MenuItem disabled={el.disabled} value={el.value} key={el.value}>
                  {el.title}
                </MenuItem>
              ))}
            </TextField>
          )}
        />

        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <Controller
              name="product_count"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  type="number"
                  label="Количество товаров"
                  variant="outlined"
                  error={!!errors.product_count}
                  helperText={errors.product_count?.message}
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Controller
              name="discount"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  type="number"
                  label="Скидка (%)"
                  variant="outlined"
                  error={!!errors.discount}
                  helperText={errors.discount?.message}
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>
        </Grid>

        <Controller
          name="is_active"
          control={control}
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox {...field} checked={field.value} />}
              label="Активность"
              sx={{ mt: 1 }}
            />
          )}
        />
        <Controller
          name="is_visible"
          control={control}
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox {...field} checked={field.value} />}
              label="Видимость"
              sx={{ mt: 1 }}
            />
          )}
        />

        <Box sx={{ textAlign: "right" }}>
          <LoadingButton type="submit" variant="contained" loading={btnLoading}>
            Сохранить
          </LoadingButton>
        </Box>
      </form>
    </Modal>
  );
});

export default NewBadgeModal;
