import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  Autocomplete,
  TextField,
} from "@mui/material";
import { $modals } from "store/modals";
import { isMobile } from "react-device-detect";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import EditIcon from "@mui/icons-material/Edit";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import Add from "@mui/icons-material/Add";
import { $tags } from "store/tags/tags";
import { $products } from "store/tags/productsInTags";
import ProductsTable from "./productsTable";
import {
  ADD_PRODUCT_TO_TAG_MODAL,
  UPLOAD_TXTFILE_TO_TAG_MODAL,
  NEW_TAG_MODAL,
  EDIT_TAG_MODAL,
} from "ui-components/modal/modals";
import { $sidebar } from "store/sidebar";

const Tags = observer(() => {
  useEffect(() => {
    $tags.fetchTagsList("fetchAll");
    $sidebar.setPageTitle("Товары и теги");
  }, []);

  const handleChange = (newValue) => {
    $products.setCurrentTagId(newValue.id);
    $products.fetchProductsInTagList($products.currentTagId);
  };
  const addItem = () => {
    $modals.show(ADD_PRODUCT_TO_TAG_MODAL, $products.currentTagId);
  };
  const uploadFile = () => {
    $modals.show(UPLOAD_TXTFILE_TO_TAG_MODAL, $products.currentTagId);
  };
  const downloadCsv = () => {
    $products.downloadBarcodesFile($products.currentTagId);
  };
  const ButtonWrapper = ({ children }) => (
    <Box
      sx={isMobile ? { fontSize: 12, ml: 0, mb: 2 } : { fontSize: 18, ml: 2 }}
    >
      {children}
    </Box>
  );

  const createTag = () => {
    $modals.show(NEW_TAG_MODAL);
  };
  const editTag = () => {
    $modals.show(EDIT_TAG_MODAL, $products.currentTagId);
  };

  return (
    <>
      <Box
        sx={
          isMobile
            ? {}
            : { display: "flex", justifyContent: "space-between", mb: 2 }
        }
      >
        <Box
          sx={isMobile ? {} : { display: "flex", justifyContent: "flex-start" }}
        >
          <FormControl
            fullWidth={isMobile}
            size="small"
            sx={{ minWidth: 250, mb: isMobile ? 2 : 0 }}
          >
            {$tags.tagsList && (
              <Autocomplete
                key={$tags.tagsList.length}
                size="small"
                clearOnBlur
                disableClearable
                getOptionLabel={(option) =>
                  `${option.value.toString()} (${option.products_count})`
                }
                onChange={(event, newValue) => {
                  handleChange(newValue);
                }}
                options={$tags.tagsList}
                renderInput={(params, key) => (
                  <TextField key={key} {...params} label="Выберите группу" />
                )}
              />
            )}
          </FormControl>
          <div
            style={
              isMobile ? {} : { display: "flex", justifyContent: "flex-start" }
            }
          >
            <ButtonWrapper>
              <Button
                fullWidth={isMobile}
                variant="contained"
                startIcon={<Add />}
                onClick={createTag}
              >
                Создать
              </Button>
            </ButtonWrapper>
            {$products.currentTagId && (
              <ButtonWrapper>
                <Button
                  fullWidth={isMobile}
                  variant="contained"
                  startIcon={<EditIcon />}
                  onClick={editTag}
                >
                  Редактировать
                </Button>
              </ButtonWrapper>
            )}
          </div>
        </Box>
        {$products.currentTagId && (
          <div
            style={
              isMobile ? {} : { display: "flex", justifyContent: "flex-end" }
            }
          >
            <ButtonWrapper>
              <Button
                fullWidth={isMobile}
                variant="contained"
                startIcon={<Add />}
                onClick={addItem}
              >
                Добавить товар
              </Button>
            </ButtonWrapper>
            <ButtonWrapper>
              <Button
                fullWidth={isMobile}
                variant="contained"
                startIcon={<AttachFileIcon />}
                onClick={uploadFile}
              >
                Загрузить файл
              </Button>
            </ButtonWrapper>
            {$products.productsInTagList.length > 0 && (
              <ButtonWrapper>
                <Button
                  fullWidth={isMobile}
                  variant="contained"
                  startIcon={<UploadFileIcon />}
                  onClick={downloadCsv}
                >
                  Выгрузить CSV
                </Button>
              </ButtonWrapper>
            )}
          </div>
        )}
      </Box>
      {!isMobile && <ProductsTable list={$products.productsInTagList} />}
    </>
  );
});

export default Tags;
