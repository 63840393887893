import React from "react";
import {
  Box,
  Alert
} from "@mui/material";
import {Modal} from "ui-components/modal";
import {useState} from "react";
import {$products} from "store/tags/productsInTags";
import {$modals} from "store/modals";
import {observer} from "mobx-react-lite";
import {LoadingButton} from "@mui/lab";

const AddProductToTagModal = observer(() => {

  const [file, setFile] = useState(null);
  const [btnLoading, setBtnLoading] = useState(false);
  const [error, setError] = useState(null);

  const onSubmit = () => {
    setBtnLoading(true);
    setError(null);

    $products.uploadBarcodesFile(file)
      .then(() => {
        $products.fetchProductsInTagList($products.currentTagId)
        $modals.close()
      })
      .catch(err => {
        if (err.response) {
          setError(err.response.data.message)
        }
      })
      .finally(() => setBtnLoading(false))
  }

  return (
    <Modal title="Загрузить файл с штрих-кодами" subheader={
      <>
        Выберите файл <b>.txt</b>, каждый код должен начинаться с новой строки.
      </>
    }>
      {error && <Alert sx={{mb: 3}} severity="error">{error}</Alert>}
      <input
        type="file"
        accept=".txt"
        onChange={e => setFile(e.target.files[0])}
      />
      <Box sx={{textAlign: 'right', mt:3}}>
        <LoadingButton
          variant="contained"
          onClick={onSubmit}
          loading={btnLoading}
        >
          Загрузить
        </LoadingButton>
      </Box>
    </Modal>
  )
});

export default AddProductToTagModal;