import React from "react";
import {Box, Checkbox, FormControlLabel, Grid, InputAdornment, MenuItem, TextField} from "@mui/material";
import {Modal} from "ui-components/modal";
import {Controller, useForm} from 'react-hook-form';
import {$modals} from "store/modals";
import {observer} from "mobx-react-lite";
import {LoadingButton} from "@mui/lab";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {$badges, badgeTypes} from "../../../store/badges/badges";
import {ColorPicker} from "./colorPicker";
import ColorizeIcon from "@mui/icons-material/Colorize";
import FormatColorFillIcon from "@mui/icons-material/FormatColorFill";

const EditBadgeModal = observer(() => {
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    watch,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      title: $modals.params.title,
      bg_color: $modals.params.bg_color || '',
      expired_at: $modals.params.expired_at,
      is_active: !!$modals.params.is_active,
      is_visible: !!$modals.params.is_visible,
      discount: $modals.params.discount || '',
      product_count: $modals.params.product_count || '',
      text_color: $modals.params.text_color,
      type: $modals.params.type,
      url: $modals.params.url || '',
    },
  });

  const [btnLoading, setBtnLoading] = React.useState(false);
  const [colorPicker, setColorPicker] = React.useState(null);

  const deleteBadge = () => {
    if(window.confirm('Удалить?')) {
      setBtnLoading(true);
      $badges
        .delete($modals.params.id)
        .then(() => {
          $badges.fetchBadges("fetchAll");
          // $products.setProductsInTagList([]);
        })
        .finally(() => {
          setBtnLoading(false);
          $modals.close();
        });
    }
  };

  const onSubmit = async (data) => {
    setBtnLoading(true);
    try {
      await $badges.update($modals.params.id, data);
      await $badges.fetchBadges("fetchAll");
      $modals.close();
    } catch (err) {
      if (err.response?.data && err.response.status === 422) {
        const errorResponse = err.response.data.errors;
        for (const key in errorResponse) {
          setError(key, { type: 'manual', message: errorResponse[key][0] }); // Устанавливаем ошибки вручную
        }
      }
    } finally {
      setBtnLoading(false);
    }
  };

  const setColorClicked = (type) => {
    setColorPicker(type === colorPicker ? null : type);
  };

  const onChangeColor = (value) => {
    if (colorPicker === 'text') {
      setValue('text_color', value);
    } else {
      setValue('bg_color', value);
    }
  };

  return (
    <Modal title="Редактировать">
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          required
          error={!!errors.title}
          {...register('title', { required: 'Название обязательно' })}
          fullWidth
          sx={{ mb: 3 }}
          label="Название"
          variant="outlined"
          helperText={errors.title?.message}
        />

        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <Controller
              name="text_color"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment onClick={() => setColorClicked('text')} sx={{ cursor: 'pointer' }} position="end">
                        <ColorizeIcon sx={{ color: colorPicker === 'text' ? '#067ed5' : '#101010' }} />
                      </InputAdornment>
                    ),
                  }}
                  required
                  fullWidth
                  sx={{ mb: 3 }}
                  label="Цвет (пример #FFFFFF)"
                  variant="outlined"
                  error={!!errors.text_color}
                  helperText={errors.text_color?.message}
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Controller
              name="bg_color"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment onClick={() => setColorClicked('bg')} sx={{ cursor: 'pointer' }} position="end">
                        <FormatColorFillIcon sx={{ color: colorPicker === 'bg' ? '#067ed5' : '#101010' }} />
                      </InputAdornment>
                    ),
                  }}
                  required
                  fullWidth
                  sx={{ mb: 3 }}
                  label="Фон (пример #000000)"
                  variant="outlined"
                  error={!!errors.bg_color}
                  helperText={errors.bg_color?.message}
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>
        </Grid>

        {colorPicker && (
          <ColorPicker
            title={watch('title')}
            type={colorPicker}
            textColor={watch('text_color')}
            bgColor={watch('bg_color')}
            setColor={onChangeColor}
          />
        )}

        <TextField
          {...register('expired_at')}
          fullWidth
          sx={{ mb: 3 }}
          label="Дата окончания (не обязательное поле)"
          type="date"
          InputLabelProps={{ shrink: true }} // Убедитесь, что метка не перекрывает поле
        />

        <TextField
          {...register('url')}
          fullWidth
          sx={{ mb: 3 }}
          label="Ссылка на правила"
          variant="outlined"
        />

        <Controller
          name="type"
          control={control}
          render={({ field }) => (
            <TextField
              sx={{ mb: 3 }}
              select
              {...field}
              fullWidth
              label="Тип"
              variant="outlined"
            >
              {badgeTypes.map((el) => (
                <MenuItem disabled={el.disabled} value={el.value} key={el.value}>
                  {el.title}
                </MenuItem>
              ))}
            </TextField>
          )}
        />

        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <Controller
              name="product_count"
              control={control}
              render={({ field }) => (
                <TextField
                  {...register('product_count')}
                  {...field}
                  fullWidth
                  type="number"
                  label="Количество товаров"
                  variant="outlined"
                  error={!!errors.product_count}
                  helperText={errors.product_count?.message}
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Controller
              name="discount"
              control={control}
              render={({ field }) => (
                <TextField
                  {...register('discount')}
                  {...field}
                  fullWidth
                  type="number"
                  label="Скидка (%)"
                  variant="outlined"
                  error={!!errors.discount}
                  helperText={errors.discount?.message}
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>
        </Grid>

        <FormControlLabel
          sx={{ mt: 1 }}
          control={
            <Controller
              name="is_active"
              control={control}
              render={({ field }) => (
                <Checkbox
                  {...field}
                  checked={field.value} // Убедитесь, что checked получает правильное значение
                />
              )}
            />
          }
          label="Активность"
        />
        <FormControlLabel
          sx={{ mt: 1 }}
          control={
            <Controller
              name="is_visible"
              control={control}
              render={({ field }) => (
                <Checkbox
                  {...field}
                  checked={field.value} // Убедитесь, что checked получает правильное значение
                />
              )}
            />
          }
          label="Видимость"
        />

        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <LoadingButton
            sx={{ mr: 3 }}
            variant="contained"
            color="error"
            startIcon={<DeleteOutlineIcon />}
            onClick={deleteBadge}
          >
            Удалить
          </LoadingButton>
          <LoadingButton
            variant="contained"
            type="submit"
            loading={btnLoading}
          >
            Сохранить
          </LoadingButton>
        </Box>
      </form>
    </Modal>
  );
});
export default EditBadgeModal;