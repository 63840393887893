import {types} from "mobx-state-tree";
import {api, v3Endpoint} from "../../api";
import {flow} from "mobx";
import {$spinner} from "../spinner";
import {$snackbar} from "../snackbar";
import {union} from "lodash";
import {$products} from "./productsInBadge";

export const badgeTypes = [
  {
    title: 'Акционный',
    value: 'discount',
    disabled: true
  },
  {
    title: 'Информационный',
    value: 'info',
    disabled: false
  },
];

export const $badges = types
  .model({
    list: types.frozen([]),
    limit: types.optional(types.number, 50),
    offset: types.optional(types.number, 0),
    hasMore: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    fetchBadges: flow(function* (type) {
      if (type !== "paginate") {
        self.resetPaginate();
        self.setList([]);
      }
      try {
        $spinner.show();
        const res = yield api(v3Endpoint)
          .get(`badges`, {
            params: {
              limit:
                type !== "paginate" && type === "fetchAll" ? 1000 : self.limit,
              offset: self.offset,
            },
          })
          .then((res) => res.data);
        self.setHasMore(res.length >= self.limit);
        self.setList(type === "paginate" ? union(self.list, res) : res);
        $spinner.hide();
      } catch (err) {
        $spinner.hide();
        console.error("fetch badge list: ", err);
        throw err;
      }
    }),

    create: flow(function* (params) {
      try {
        const res = yield api(v3Endpoint)
          .post(`badges`, params)
          .then((res) => res.data);
        $products.setCurrentBadge(res);
      } catch (err) {
        $snackbar.show("Ошибка сохранения");
        throw err;
      }
    }),

    update: flow(function* (id, params) {
      try {
        yield api(v3Endpoint)
          .put(`badges/${id}`, params)
          .then((res) => res.data);
        $products.setCurrentBadge({});

      } catch (err) {
        $snackbar.show("Ошибка сохранения");
        throw err;
      }
    }),

    delete: flow(function* (id) {
      try {
        yield api(v3Endpoint)
          .delete(`badges/${id}`)
          .then((res) => res.data);
        $products.setCurrentBadge({});
      } catch (err) {
        $snackbar.show("Ошибка удаления");
        throw err;
      }
    }),
    setList(data) {
      self.list = data;
    },
    paginate() {
      self.offset += self.limit;
      self.fetchBadges("paginate");
    },
    resetPaginate() {
      self.offset = 0;
    },
    setHasMore(value) {
      self.hasMore = value;
    },
    setLimit(data) {
      self.limit = data;
    },
  }))
  .create();
