import React from "react";
import {observer} from "mobx-react-lite";
import {Routes, Route, Navigate} from "react-router-dom";
import Dashboard from "../pages/dashboard";
import Login from "../pages/login";
import Customers from "../pages/customers";
import Complaints from "../pages/complaints";
import Remarks from "../pages/remarks";
import Suggestions from "../pages/suggestions";
import PageNotFound from "../pages/notFound";
import Users from "../pages/users";
import Promo from "../pages/promo";
import ProductsAndTags from "../pages/tags";
import Roles from "../pages/roles";
import {$permissions} from "../store/permissions/permissions";
import Permissions from "../pages/permissions";
import Chat from "../pages/chat";
import Notifications from "../pages/notifications";
import NotificationsForm from "../pages/notificationsForm";
import Reviews from "../pages/reviews";
import ChatStats from "../pages/chatStats";
import Receipts from "../pages/receipts";
import Badges from "../pages/badges";

export const appRoutes = {
  dashboard: {
    label: "Главная",
    value: "dashboard",
  },
  customers: {
    label: "Покупатели",
    value: "customers",
  },
  remarks: {
    label: "Ошибки в товарах",
    value: "remarks",
  },
  complaints: {
    label: "Жалобы",
    value: "complaints",
  },
  notifications: {
    label: "Уведомления",
    value: "notifications",
  },
  notificationsForm: {
    label: "Создать рассылку",
    value: "notificationsForm",
  },
  suggestions: {
    label: "Шаблоны",
    value: "suggestions",
  },
  chat: {
    label: "Сообщения",
    value: "chat",
  },
  receipts: {
    label: "Чеки заказов",
    value: "receipts",
  },
  badges: {
    label: "Бейджи",
    value: "badges",
  },
  chatStats: {
    label: "Статистика",
    value: "chat/stats",
  },
  channels: {
    label: "Каналы",
    value: "channels",
  },
  users: {
    label: "Пользователи",
    value: "users",
  },
  promo: {
    label: "Акционные товары",
    value: "promo",
  },
  tags: {
    label: "Теги",
    value: "tags",
  },
  roles: {
    label: "Роли",
    value: "roles",
  },
  permissions: {
    label: "Доступы в разделы",
    value: "permissions",
  },
  reviews: {
    label: "Отзывы",
    value: "reviews",
  },
};

const AppRoutes = observer(() => {
  const ProtectedRoute = ({element, path}) => {
    if ($permissions.isAllowedForRole(path)) {
      return <Route element={element} path={path}/>;
    } else {
      return <Navigate to="/404"/>;
    }
  };

  return (
    <Routes>
      <Route exact path="/">
        <Navigate to="/dashboard"/>
      </Route>
      <ProtectedRoute
        element={<Receipts/>}
        path={appRoutes.receipts.value}
      />
      <ProtectedRoute
        element={<Badges/>}
        path={appRoutes.badges.value}
      />
      <ProtectedRoute
        element={<Dashboard/>}
        path={appRoutes.dashboard.value}
      />
      <ProtectedRoute
        element={<Customers/>}
        path={appRoutes.customers.value}
      />
      <ProtectedRoute element={<Remarks/>} path={appRoutes.remarks.value}/>
      <ProtectedRoute
        element={<Complaints/>}
        path={appRoutes.complaints.value}
      />
      <ProtectedRoute
        element={<Suggestions/>}
        path={appRoutes.suggestions.value}
      />
      <ProtectedRoute
        element={<Notifications/>}
        path={appRoutes.notifications.value}
      />
      <ProtectedRoute
        element={<NotificationsForm/>}
        path={appRoutes.notificationsForm.value}
      />
      <ProtectedRoute element={<Chat/>} path={appRoutes.chat.value}/>
      <Route element={<ChatStats/>} path={appRoutes.chatStats.value}/>
      <ProtectedRoute element={<Users/>} path={appRoutes.users.value}/>
      <ProtectedRoute element={<Reviews/>} path={appRoutes.reviews.value}/>
      <ProtectedRoute element={<Promo/>} path={appRoutes.promo.value}/>
      <ProtectedRoute
        element={<ProductsAndTags/>}
        path={appRoutes.tags.value}
      />
      <ProtectedRoute element={<Roles/>} path={appRoutes.roles.value}/>
      <ProtectedRoute
        element={<Permissions/>}
        path={appRoutes.permissions.value}
      />
      <Route element={<Login/>} path="login"/>
      <Route element={<PageNotFound/>} path="404"/>
      <Route element={<Navigate to="404" replace/>} path="*"/>
    </Routes>
  );
});

export default AppRoutes;
