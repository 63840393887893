import { types } from "mobx-state-tree";
import {api, v3Endpoint} from "../../api";
import { flow } from "mobx";
import { $spinner } from "../spinner";
import { union } from "lodash";
import iziToast from "izitoast";

export const $products = types
  .model("productsInBadge", {
    productsInBadgeList: types.frozen([]),
    currentBadge: types.frozen({}),
    limit: types.optional(types.number, 50),
    offset: types.optional(types.number, 0),
    hasMore: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    fetchProductsInBadgeList: flow(function* (type) {
      if (type !== "paginate") {
        self.resetPaginate();
        self.setProductsInBadgeList([]);
      }
      try {
        $spinner.show();
        const res = yield api(v3Endpoint)
          .get(`badges/${self.currentBadge.id}`, {
            params: {
              limit: self.limit,
              offset: self.offset,
            },
          })
          .then((res) => res.data);
        self.setHasMore(res.length >= self.limit);
        self.setProductsInBadgeList(
          type === "paginate" ? union(self.productsInTagList, res) : res
        );
      } catch (err) {
        console.error("fetch networks list: ", err);
        throw err;
      }
      $spinner.hide();
    }),
    addProductToBadge: flow(function* (productBarcode) {
      try {
        yield api(v3Endpoint)
          .post(`badges/${self.currentBadge.id}/product`, {
            barcode: productBarcode,
          })
          .then((res) => res.data)
          .then(() => {
            self.fetchProductsInBadgeList(self.currentBadge.id);
          });
      } catch (err) {
        throw err;
      }
    }),
    deleteProductFromBadge: flow(function* (productId) {
      try {
        yield api()
          .delete(`badges/${self.currentBadge.id}/product`, {
            params: {
              product_id: productId,
            },
          })
          .then((res) => res.data);

        let items = [...self.productsInBadgesList];
        self.setProductsInBadgeList(items.filter((el) => el.id !== productId));
      } catch (err) {
        throw err;
      }
    }),
    uploadBarcodesFile: flow(function* (data) {
      const formData = new FormData();
      formData.append("file", data);
      formData.append("badge_id", self.currentBadge.id);
      try {
        const res = yield api(v3Endpoint)
          .post(`badges/${self.currentBadge.id}/product/upload`, formData)
          .then((res) => res.data);
        iziToast.success({
          message: res.message,
        });
      } catch (err) {
        console.error("upload file: ", err);
        throw err;
      }
    }),
    downloadBarcodesFile: flow(function* (id) {
      yield api()
        .get(`admin/product-tags-csv/${id}`, {
          responseType: "blob",
        })
        .then((res) => {
          let date = new Date().toLocaleDateString();
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `groups_${date}.csv`);
          document.body.appendChild(link);
          link.click();
        });
    }),
    setProductsInBadgeList(data) {
      self.productsInBadgeList = data;
    },
    setCurrentBadge(badge) {
      self.currentBadge = badge;
    },
    setHasMore(value) {
      self.hasMore = value;
    },
    paginate() {
      self.offset += self.limit;
      self.fetchProductsInBadgeList("paginate");
    },
    setLimit(value) {
      self.limit = value;
    },
    resetPaginate() {
      self.offset = 0;
    },
  }))
  .create();
