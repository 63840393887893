import React from "react";
import {Modal} from "ui-components/modal";
import {$modals} from "store/modals";
import {observer} from "mobx-react-lite";
import ReactJson from 'react-json-view';

const ReceiptLink = ({params}) => {
  if(!params) {
    return 'Ошибка. Чек не найден'
  }

  const getT = (dateString) => {
    // Создаем объект даты
    const date = new Date(dateString);

    // Добавляем 3 часа
    date.setHours(date.getHours() + 3);

    // Получаем компоненты даты
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Месяцы начинаются с 0
    const day = String(date.getUTCDate()).padStart(2, '0');
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');

    // Формируем итоговую строку
    return `${year}${month}${day}T${hours}${minutes}`;
  }

  if(!params.fiscal_storage_number){
    return <>fiscal data not found</>;
  }
  const fn = params.fiscal_storage_number.trim()
  const i = params.fiscal_document_number
  const fp = params.fiscal_attribute
  const s = params.settlements[0].amount.value;

  return (
    <a
      href={`https://consumer.1-ofd.ru/ticket?t=${getT(params.registered_at)}&s=${s}&fn=${fn}&i=${i}&fp=${fp}&n=1`}
      target="_blank">Открыть чек
    </a>
  )
}

const ReceiptDetailsModal = observer(() => {

  const params = $modals.params;

  return (
    <Modal
      title="Информация о чеке от Yookassa"
      subheader={<ReceiptLink params={params}/>}
    >
      <ReactJson
        displayDataTypes={false}
        displayObjectSize={false}
        enableClipboard={false}
        src={params}
        theme="rjv-default"
        collapsed={false}/>
    </Modal>
  );
});

export default ReceiptDetailsModal;
