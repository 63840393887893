import {Grid, Skeleton} from "@mui/material";
import {HexColorPicker} from "react-colorful";
import React from "react";

export const ColorPicker = ({title, type, setColor, textColor, bgColor}) => {
  return (
    <div style={{marginBottom: '24px'}}>
      <Grid container spacing={0}>
        <Grid item xs={6}>
          <div>
            <HexColorPicker color={type === 'text' ? textColor : bgColor} onChange={setColor}/>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div style={{position: 'relative'}}>
            <Skeleton variant="rounded" height={140}/>
            <Skeleton/>
            <Skeleton width="60%"/>
            <div style={{
              position: 'absolute',
              background: bgColor ? bgColor : '#000',
              color: textColor ? textColor : '#FFF',
              // border: `1px solid ${textColor}`,
              padding: '1px 5px',
              fontSize: '12px',
              borderRadius: '4px',
              marginTop:'4px'
            }}>
              {title ? title : 'Это пример'}
            </div>

          </div>
        </Grid>
      </Grid>
    </div>
  );
}
